<template>
  <div>
    <b-card no-body>
      <job-header
        profile-error
        profile-valid
        :job="job"
        :job-remaining="jobRemaining"
        :job-closed="jobClosed"
        :time-remaining="timeRemaining"
        :find-department="findDepartment"
      />

      <b-card-body>
        <div class="d-flex justify-content-between">
          <b-card-text
            v-if="
              typeof jobsSubmissions[jobID] !== 'undefined' &&
              jobsSubmissions[jobID].length > 0
            "
            style="margin-top: auto; margin-bottom: auto"
          >
            Currently at {{ currentIndex + 1 }} of
            {{
              typeof jobsSubmissions[jobID] !== 'undefined' &&
              jobsSubmissions[jobID].length
            }}
            applicants
          </b-card-text>
          <b-card-text v-else
            >There are no submissions available for this job yet</b-card-text
          >
          <div class="d-flex justify-content-end">
            <b-button-group class="mt-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="`outline-${
                  currentIndex === 0 ? 'secondary' : 'primary'
                }`"
                :disabled="currentIndex === 0"
                @click="currentIndex--"
              >
                Previous
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="`outline-${
                  typeof jobsSubmissions[jobID] !== 'undefined' &&
                  (jobsSubmissions[jobID].length === 0 ||
                    currentIndex === jobsSubmissions[jobID].length - 1)
                    ? 'secondary'
                    : 'primary'
                }`"
                :disabled="
                  typeof jobsSubmissions[jobID] !== 'undefined' &&
                  (jobsSubmissions[jobID].length === 0 ||
                    currentIndex === jobsSubmissions[jobID].length - 1)
                "
                @click="currentIndex++"
              >
                Next
              </b-button>
            </b-button-group>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- <process-applicant
      :application="currentApplicant"
    /> -->

    <b-alert
      v-if="
        typeof jobsSubmissions[jobID] !== 'undefined' &&
        jobsSubmissions[jobID].length === 0
      "
      variant="primary"
      show
    >
      <div class="alert-body">
        <span
          ><strong>Hey There!</strong> There are currently 0 submissions of
          applications available linked to this job yet. Please check back later
          to process the shortlist.</span
        >
      </div>
    </b-alert>

    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <b-card v-if="user">
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="user.data.picture"
                :text="
                  avatarText(`${user.data.given_name} ${user.data.family_name}`)
                "
                :variant="`light-primary`"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0">
                    {{ user.data.given_name
                    }}{{
                      user.data.middle_name
                        ? ` ${user.data.middle_name} `
                        : ' '
                    }}{{ user.data.family_name }}
                  </h4>
                  <span class="card-text"
                    >National ID:
                    <strong>{{
                      user.information.nationalID || 'N/A'
                    }}</strong></span
                  ><br />
                  <span class="card-text"
                    >Birthdate:
                    <strong>{{ user.data.birthdate || 'N/A' }}</strong></span
                  >
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-2">
              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-primary" rounded>
                  <feather-icon icon="ClockIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ user.information.yearsOfExperience }} year{{
                      user.information.yearsOfExperience === '1' ? '' : 's'
                    }}
                  </h5>
                  <small>Experience</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar
                  :variant="`light-${
                    user.information.disabled ? 'success' : 'warning'
                  }`"
                  rounded
                >
                  <feather-icon icon="SlashIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{
                      user.information.disabled
                        ? user.information.disability
                        : 'N/A'
                    }}
                  </h5>
                  <small>Disability Status</small>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <b-avatar :variant="`light-success`" rounded>
                  <feather-icon icon="SmileIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ user.data.gender }}
                  </h5>
                  <small>Gender</small>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Ward</span>
                </th>
                <td class="pb-50">
                  {{ user.information.ward }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Sub County</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ user.information.sub_county }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="MapPinIcon" class="mr-75" />
                  <span class="font-weight-bold">County</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ user.information.county }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Religion</span>
                </th>
                <td class="pb-50">
                  {{ user.information.religion }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon icon="GitMergeIcon" class="mr-75" />
                  <span class="font-weight-bold">Marriage Status</span>
                </th>
                <td>
                  {{ user.information.married }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="8" md="9">
          <b-card v-if="user">
            <h5>COVER INFORMATION</h5>
            <b-card-text>
              {{ currentApplicant.cover }}
            </b-card-text>
            <div class="divider my-2">
              <div class="divider-text">
                Proffessional Qualifications & Referees
              </div>
            </div>
            <b-tabs>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="BriefcaseIcon" />
                  <span>Experience</span>
                </template>

                <app-collapse accordion type="margin">
                  <app-collapse-item
                    v-for="(experience, index) in user.experience"
                    :key="index"
                    :title="`${index + 1}. ${experience.designation} at ${
                      experience.company
                    }`"
                  >
                    <b-card-text>
                      <b-col md="8" class="mt-2">
                        <dl class="row">
                          <dt class="col-sm-3">Company / Institution</dt>
                          <dd class="col-sm-9">
                            {{ experience.company }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Designation</dt>
                          <dd class="col-sm-9">
                            {{ experience.designation }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Dates</dt>
                          <dd class="col-sm-9">
                            {{ experience.start_date }} to
                            {{ experience.end_date }}
                            <br />
                            <b-badge
                              pill
                              :variant="`light-secondary`"
                              class="text-capitalize"
                              >{{
                                preciseDifference(
                                  experience.end_date,
                                  experience.start_date,
                                )
                              }}
                            </b-badge>
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Description</dt>
                          <dd class="col-sm-9">
                            {{ experience.description }}
                          </dd>
                        </dl>
                      </b-col>
                    </b-card-text>
                  </app-collapse-item>
                </app-collapse>
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AtSignIcon" />
                  <span>Referees</span>
                </template>

                <app-collapse accordion type="margin">
                  <app-collapse-item
                    v-for="(referee, index) in user.referees"
                    :key="index"
                    :title="`${index + 1}. ${referee.name} - ${
                      referee.company
                    }`"
                  >
                    <b-card-text>
                      <b-col md="8" class="mt-2">
                        <dl class="row">
                          <dt class="col-sm-3">Name</dt>
                          <dd class="col-sm-9">
                            {{ referee.name }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Company / Institution</dt>
                          <dd class="col-sm-9">
                            {{ referee.company }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Designation</dt>
                          <dd class="col-sm-9">
                            {{ referee.designation }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Phone</dt>
                          <dd class="col-sm-9">
                            <b-link :href="`tel:${referee.phone}`">{{
                              referee.phone
                            }}</b-link>
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Email</dt>
                          <dd class="col-sm-9">
                            <b-link :href="`mailto:${referee.email}`">{{
                              referee.email
                            }}</b-link>
                          </dd>
                        </dl>
                      </b-col>
                    </b-card-text>
                  </app-collapse-item>
                </app-collapse>
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="BookOpenIcon" />
                  <span>Education</span>
                </template>

                <app-collapse accordion type="margin">
                  <app-collapse-item
                    v-for="(education, index) in user.education"
                    :key="index"
                    :title="`${index + 1}. ${education.examination} ${
                      education.institution
                    }`"
                  >
                    <b-card-text>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        pill
                        :disabled="downloading"
                        @click.prevent="downloadFile(education.file)"
                      >
                        <feather-icon v-if="!downloading" icon="DownloadIcon" />
                        <b-spinner v-else small />
                        Download File
                      </b-button>

                      <b-col md="8" class="mt-2">
                        <dl class="row">
                          <dt class="col-sm-3">Level</dt>
                          <dd class="col-sm-9">
                            {{ education.level }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Institution</dt>
                          <dd class="col-sm-9">
                            {{ education.institution }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Certificate</dt>
                          <dd class="col-sm-9">
                            {{ education.examination }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Grade</dt>
                          <dd class="col-sm-9">
                            {{ education.grade }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Certificate Number</dt>
                          <dd class="col-sm-9">
                            {{ education.cert_no }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Years</dt>
                          <dd class="col-sm-9">
                            {{ education.year_started }} To
                            {{ education.year_completed }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Uploaded On</dt>
                          <dd class="col-sm-9">
                            {{
                              formatDate(education.createdAt, {
                                month: 'long',
                                day: 'numeric',
                                weekday: 'long',
                                year: 'numeric',
                              })
                            }}
                            at {{ formatTime(education.createdAt) }}
                          </dd>
                        </dl>
                      </b-col>
                    </b-card-text>
                  </app-collapse-item>
                </app-collapse>
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="FileIcon" />
                  <span>Documents</span>
                </template>

                <app-collapse accordion type="margin">
                  <app-collapse-item
                    v-for="(document, index) in user.documents"
                    :key="index"
                    :title="`${index + 1}. ${document.description}`"
                  >
                    <b-card-text>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        pill
                        :disabled="downloading"
                        @click.prevent="downloadFile(document.id)"
                      >
                        <feather-icon v-if="!downloading" icon="DownloadIcon" />
                        <b-spinner v-else small />
                        Download File
                      </b-button>

                      <b-col md="8" class="mt-2">
                        <dl class="row">
                          <dt class="col-sm-3">Description</dt>
                          <dd class="col-sm-9">
                            {{ document.description }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Original Name</dt>
                          <dd class="col-sm-9">
                            {{ document.name }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Size</dt>
                          <dd class="col-sm-9">
                            {{
                              (parseInt(document.size, 10) / 1000000).toFixed(4)
                            }}
                            MB
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Mime Type</dt>
                          <dd class="col-sm-9">
                            {{ document.type }}
                          </dd>
                        </dl>
                        <dl class="row">
                          <dt class="col-sm-3">Uploaded on</dt>
                          <dd class="col-sm-9">
                            {{
                              formatDate(document.createdAt, {
                                month: 'long',
                                day: 'numeric',
                                weekday: 'long',
                                year: 'numeric',
                              })
                            }}
                            at {{ formatTime(document.createdAt) }}
                          </dd>
                        </dl>
                      </b-col>
                    </b-card-text>
                  </app-collapse-item>
                </app-collapse>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>

        <b-col lg="4" md="3">
          <b-card v-if="user && !currentApplicant.processed" no-body>
            <b-card-header>
              <b-card-title>Shortlist Verdict</b-card-title>
              <b-card-title class="text-primary">
                <feather-icon icon="UserIcon" class="mr-1" />
                <span class="text-small"
                  >{{ user.data.given_name }} {{ user.data.family_name }}
                </span>
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <!-- form -->
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Remarks (Optional)"
                      label-for="remarks"
                      class="mb-2"
                    >
                      <b-form-textarea
                        id="remarks"
                        rows="3"
                        placeholder="Remarks (Optional)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="primary"
                      @click="shortListApplicant"
                    >
                      Shortlist Applicant
                    </b-button>
                  </b-col>
                  <b-col cols="12" class="mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      variant="danger"
                      @click="rejectApplicant"
                    >
                      Reject Application
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!--/ form -->
            </b-card-body>
          </b-card>
          <b-alert
            v-if="currentApplicant.processed"
            :variant="`${currentApplicant.shortListed ? 'primary' : 'warning'}`"
            show
          >
            <div class="alert-body">
              <span
                >This applicant has already been processed.
                {{ user.data.gender === 'Male' ? 'He' : 'She' }} was
                <strong>
                  {{ currentApplicant.shortListed ? '' : 'not' }}
                  shortlisted</strong
                >
                for this job.{{
                  currentApplicant.remarks
                    ? ''
                    : ' No remarks were provided for this applicant'
                }}</span
              >
            </div>
          </b-alert>
          <b-card v-if="currentApplicant.remarks">
            <h5><strong>REMARKS</strong></h5>
            {{ currentApplicant.remarks }}
          </b-card>
        </b-col>
      </b-row>

      <template #overlay>
        <div class="text-center">
          <b-spinner type="grow" />
          <p class="mt-2">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  BCard,
  BCardBody,
  BButton,
  BButtonGroup,
  BCardText,
  BOverlay,
  BTab,
  BTabs,
  BAvatar,
  BCol,
  BRow,
  BSpinner,
  BAlert,
  BBadge,
  BFormGroup,
  BFormTextarea,
  BCardHeader,
  BCardTitle,
  BForm,
  BLink,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { formatDate, avatarText } from '@core/utils/filter'
import axios from '@axios'
import { mapState } from 'vuex'

import JobHeader from './components/JobHeader.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BButtonGroup,
    BCardText,
    BOverlay,
    BTab,
    BTabs,
    BAvatar,
    BCol,
    BRow,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BAlert,
    BFormGroup,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BForm,
    BLink,

    JobHeader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentIndex: 0,
      downloading: false,

      jobID: this.$route.params.id,
      applicantData: {},
      currentApplicant: {},
      submissions: [],
      loading: false,
      remarks: '',
    }
  },
  computed: {
    ...mapState('jobs', ['jobs', 'jobsSubmissions']),
    ...mapState('departments', ['departments']),
    ...mapState('app', ['cacheDocuments']),
    jobOpen() {
      return moment(new Date()).isSameOrAfter(moment(this.job.opening_date))
    },
    jobClosed() {
      return moment(this.job.closing_date).isSameOrBefore(moment(new Date()))
    },
    job() {
      if (this.jobID) {
        if (this.jobs.length) {
          const details = this.jobs.find(e => e.id === this.jobID)
          if (!details) {
            this.showToast(
              'Error',
              'Job details cannot be found at the moment. Please try again later.',
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
          return details
        }
        // eslint-disable-next-line vue/no-async-in-computed-properties
        this.$store.dispatch('jobs/fetchJob', { id: this.jobID }).catch(err => {
          this.$router.push({ name: 'jobs' })
          this.showToast(
            'Error',
            `Failed to fetch job data. Please try again later. Error: ${err.message}`,
          )
        })
        return {}
      }
      return {}
    },
    //
    user() {
      const { currentApplicant } = this
      // eslint-disable-next-line vue/no-async-in-computed-properties
      // this.fetchApplicantData()
      if (
        Object.keys(currentApplicant).length &&
        Object.keys(this.applicantData).length
      ) {
        return { data: currentApplicant.uid, ...this.applicantData }
      }
      return false
    },
    filterJobSubmission() {
      return this.jobsSubmissions[this.jobID] || []
    },
  },
  watch: {
    async currentIndex() {
      await this.fetchApplicantData()
    },
    jobsSubmissions(v) {
      if (this.jobID && `${this.jobID}` in v) {
        this.submissions = v[this.jobID]
      } else {
        console.log('Something went wrong')
      }
    },
  },
  async created() {
    if (this.jobID && !(`${this.jobID}` in this.jobsSubmissions)) {
      await this.$store
        .dispatch('jobs/fetchJobSubmissions', { id: this.jobID })
        .then(async () => {
          this.submissions = this.filterJobSubmission
          await this.fetchApplicantData()
        })
    }

    if (`${this.jobID}` in this.jobsSubmissions) {
      await this.fetchApplicantData()
    }

    if (this.departments.length === 0) {
      this.$store.dispatch('departments/fetchDepartments')
    }
  },
  methods: {
    avatarText,
    formatDate,
    getConfirmation(message, variant) {
      return this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Confirm Action',
          size: 'sm',
          okVariant: variant,
          okTitle: 'Proceed',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => value)
        .catch(err => console.err(err))
    },
    async shortListApplicant() {
      this.loading = true
      await this.getConfirmation(
        'Please confirm if you want to proceed and shortlist this applicant. The applicant may receive a message confirming this shortlisting.',
        'success',
      ).then(value => {
        if (value) {
          this.$store
            .dispatch('jobs/shortlistApplicant', {
              jid: this.jobID,
              uaid: this.currentApplicant.id,
              remarks: this.remarks,
            })
            .then(() => {
              this.$forceUpdate()
              this.remarks = ''
              const subs = this.jobsSubmissions[this.jobID]
              if (
                typeof subs !== 'undefined' &&
                this.currentIndex + 1 === subs.length
              ) {
                this.$router.push({
                  name: 'job-dashboard',
                  params: this.$route.params,
                })
                this.showToast(
                  'All Done',
                  'That was the last applicant. Returning back to job dashboard.',
                  'Bell',
                  'success',
                )
              } else {
                this.showToast(
                  'Moving to next applicant',
                  'Automatically opened next client.',
                  'Bell',
                  'success',
                )
                this.currentIndex += 1
              }
              this.showToast(
                'Success',
                'Applicant successfully shortlisted',
                'Bell',
                'success',
              )
            })
            .catch(err => this.handleErrors(err))
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async rejectApplicant() {
      await this.getConfirmation(
        'Please confirm if you want to proceed and REJECT this applicant.',
        'danger',
      ).then(value => {
        if (value) {
          this.$store
            .dispatch('jobs/rejectApplicant', {
              jid: this.jobID,
              uaid: this.currentApplicant.id,
              remarks: this.remarks,
            })
            .then(() => {
              this.$forceUpdate()
              this.remarks = ''
              const subs = this.jobsSubmissions[this.jobID]
              if (
                typeof subs !== 'undefined' &&
                this.currentIndex + 1 === subs.length
              ) {
                this.$router.push({
                  name: 'job-dashboard',
                  params: this.$route.params,
                })
                this.showToast(
                  'All Done',
                  'That was the last applicant. Returning back to job dashboard.',
                  'Bell',
                  'success',
                )
              } else {
                this.showToast(
                  'Moving to next applicant',
                  'Automatically opened next client.',
                  'Bell',
                  'success',
                )
                this.currentIndex += 1
              }
              this.showToast(
                'Success',
                'Applicant successfully rejected',
                'Bell',
                'success',
              )
            })
            .catch(err => this.handleErrors(err))
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    handleErrors(err) {
      this.loading = false
      if (err.response) {
        if ('data' in err.response) {
          if ('data' in err.response.data) {
            if (Array.isArray(err.response.data.data)) {
              err.response.data.data.forEach(param => {
                this.showToast(
                  `Error ${err.response.status}`,
                  param.message,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
              })
            }
          } else {
            this.showToast(
              `Error ${err.response.status}`,
              `${err.response.data.message}`,
              'X',
              'danger',
              // eslint-disable-next-line comma-dangle
              15000,
            )
          }
        } else {
          this.showToast(
            `Error ${err.response.status}`,
            `${err.response.statusText}`,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
          // eslint-disable-next-line function-paren-newline
        }
      } else {
        this.showToast(
          'Error',
          `${err.message}`,
          'X',
          'danger',
          // eslint-disable-next-line comma-dangle
          15000,
        )
        // eslint-disable-next-line function-paren-newline
      }
    },
    formatTime(d) {
      return moment(d).format('HH:mm:ss a')
    },
    preciseDifference(sd, ed) {
      const d1 = new Date(sd)
      const d2 = new Date(ed)
      let dy = d1.getYear() - d2.getYear()
      let dm = d1.getMonth() - d2.getMonth()
      let dd = d1.getDate() - d2.getDate()

      if (dd < 0) {
        dm -= 1
        dd += 30
      }
      if (dm < 0) {
        dy -= 1
        dm += 12
      }

      return `${dy} Year${dy === 1 ? '' : 's'} ${dm} Month${
        dm === 1 ? '' : 's'
      } ${dd} Day${dd === 1 ? '' : 's'}`
    },
    reloadCurrentApplicant() {
      this.currentApplicant =
        this.jobsSubmissions[this.jobID][this.currentIndex] || {}

      return this.currentApplicant
    },
    async fetchApplicantData() {
      this.$forceUpdate()
      this.reloadCurrentApplicant()
      const { currentApplicant } = this
      if ('uid' in currentApplicant) {
        const data = currentApplicant.snapshot || null
        this.applicantData = data || false
      } else {
        this.applicantData = false
      }
      this.$forceUpdate()
    },
    timeRemaining(d) {
      const date = moment(d)
      return -moment().diff(date, 'days')
    },
    jobRemaining() {
      return moment(new Date()).unix() >= moment(this.job.opening_date)
    },
    jobPassed() {
      return (
        (this.jobRemaining
          ? this.timeRemaining(this.job.closing_date)
          : this.timeRemaining(this.job.opening_date)) < 0
      )
    },
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    findDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      if (result) {
        return result.name
      }
      return this.$store.dispatch('departments/fetchDepartments').then(() => {
        const resultDB = this.departments.find(e => e.id === id)
        return resultDB ? resultDB.name : 'Department not available'
      })
    },
    downloadFile(fileID) {
      this.downloading = true
      if (`${fileID}` in this.cacheDocuments) {
        window.open(this.cacheDocuments[fileID])
        this.showToast(
          'Opening Cached Document',
          undefined,
          'Download',
          'success',
          5000,
        )
        setTimeout(() => {
          this.downloading = false
        }, 2000)
      } else {
        axios
          .get(`/v1/files/${fileID}`)
          .then(response => {
            this.showToast(
              'Download Successful',
              undefined,
              'Download',
              'success',
              5000,
            )
            const byteString = Buffer.from(
              response.data.data.file.split(',')[1],
              'base64',
            )
            const mimeString = response.data.data.file
              .split(',')[0]
              .split(':')[1]
              .split(';')[0]
            const ab = new ArrayBuffer(byteString.length)
            const ia = new Uint8Array(ab)
            for (let i = 0; i < byteString.length; i += 1) {
              ia[i] = byteString.charCodeAt(i)
            }
            const blob = new Blob([ab], { type: mimeString })
            const url = window.URL.createObjectURL(blob)
            this.$store.commit('app/CACHE_DOCUMENT', { fileID, url })
            window.open(url)
            setTimeout(() => {
              this.downloading = false
            }, 2000)
          })
          .catch(error => console.log(error))
      }
    },
  },
}
</script>

<style></style>
